import styled from '@emotion/styled';
import CheckBox from '@/components/common/CheckBox';
import FilterListItem from '@/components/filter/FilterListItem';
import { Sort } from '@/constants/filter';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface HospitalSubFilterContainerProps {
  isReservationAvailable: boolean;
  isWorking: boolean;
  sort?: Sort;
  handleIsReservationAvailableChange: () => void;
  handleIsWorkingChange: () => void;
  handleSortOpen?: () => void;
  className?: string;
}

const HospitalSubFilterContainer = ({
  isReservationAvailable,
  isWorking,
  sort,
  handleIsReservationAvailableChange,
  handleIsWorkingChange,
  handleSortOpen,
  className,
}: HospitalSubFilterContainerProps) => {
  return (
    <SubFilterContainer className={className}>
      <LeftFilter>
        <CheckBox isChecked={isReservationAvailable} onClick={handleIsReservationAvailableChange} size={14}>
          <CheckBoxText isChecked={isReservationAvailable}>간편예약</CheckBoxText>
        </CheckBox>
        <CheckBox isChecked={isWorking} onClick={handleIsWorkingChange} size={14}>
          <CheckBoxText isChecked={isWorking}>진료중</CheckBoxText>
        </CheckBox>
      </LeftFilter>
      {sort && <FilterListItem iconSize={16} text={sort.label} iconType={'icChevronDown'} onClick={handleSortOpen} />}
    </SubFilterContainer>
  );
};

export default HospitalSubFilterContainer;

const SubFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${toRem(12)} ${toRem(16)};
`;

const LeftFilter = styled.div`
  display: flex;
  gap: ${toRem(12)};
`;

const CheckBoxText = styled.span<{ isChecked: boolean }>`
  font-weight: 400;
  font-size: ${toRem(14)};
  line-height: ${toRem(20)};
  color: ${({ isChecked }) => (isChecked ? GLOBAL_COLOR.GRAY_900 : GLOBAL_COLOR.GRAY_700)};
`;
