import { useCallback } from 'react';
import useEventTrackingStore from '@/store/useEventTrackingStore';
import { LOCATION_TYPE } from '@/utils/tracker/constants';
import { HospitalEvent, HospitalEventProperties } from '@/utils/tracker/event/hospital';

interface TrackingClickHospitalMapRecommendFilterParams {
  specialtyFilter: string[];
  timeFilter: string[];
  serviceFilter: string[];
}

interface TrackingClickHospitalMapIconParams {
  locationId: number;
  isScrap: boolean;
  isIntopet: boolean;
}

interface TrackingClickHospitalMapListReservationFilterParams {
  checkBox: boolean;
}

interface TrackingClickHospitalMapListHospitalTimeFilterParams {
  checkBox: boolean;
}

interface TrackingClickHospitalMapListScrapParams {
  checkBox: boolean;
  locationId: number; // 병원 id
  locationName: string; // 병원명
}

const useEventTrackerHospitalMap = () => {
  const { tracker } = useEventTrackingStore();

  const trackingViewHospitalMap = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALMAP);
  }, [tracker]);

  const trackingClickHospitalMapRecommendFilter = useCallback(
    ({ specialtyFilter, timeFilter, serviceFilter }: TrackingClickHospitalMapRecommendFilterParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALMAP_RECOMMFILTER, {
        [HospitalEventProperties.SPECIALTY_FILTER]: specialtyFilter,
        [HospitalEventProperties.TIME_FILTER]: timeFilter,
        [HospitalEventProperties.SERVICE_FILTER]: serviceFilter,
      });
    },
    [tracker],
  );

  const trackingClickHospitalMapSearchAgain = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALMAP_SEARCHAGAIN);
  }, [tracker]);

  const trackingClickHospitalMapIcon = useCallback(
    ({ locationId, isIntopet, isScrap }: TrackingClickHospitalMapIconParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALMAP_ICON, {
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.IS_INTOPET]: isIntopet,
        [HospitalEventProperties.IS_SCRAP]: isScrap,
      });
    },
    [tracker],
  );

  const trackingClickHospitalMapList = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALMAP_LIST);
  }, [tracker]);

  const trackingClickHospitalMapListReservationFilter = useCallback(
    ({ checkBox }: TrackingClickHospitalMapListReservationFilterParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALMAP_LIST_RESERVATIONFILTER, {
        [HospitalEventProperties.CHECK_BOX]: checkBox,
      });
    },
    [tracker],
  );

  const trackingClickHospitalMapListHospitalTimeFilter = useCallback(
    ({ checkBox }: TrackingClickHospitalMapListHospitalTimeFilterParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALMAP_LIST_HOSPITALTIMEFILTER, {
        [HospitalEventProperties.CHECK_BOX]: checkBox,
      });
    },
    [tracker],
  );

  const trackingClickHospitalMapListScrap = useCallback(
    ({ checkBox, locationName, locationId }: TrackingClickHospitalMapListScrapParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALMAP_LIST_SCRAP, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.CHECK_BOX]: checkBox,
      });
    },
    [tracker],
  );

  const trackingClickHospitalMapCurrent = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALMAP_CURRENT);
  }, [tracker]);

  return {
    trackingViewHospitalMap,
    trackingClickHospitalMapRecommendFilter,
    trackingClickHospitalMapSearchAgain,
    trackingClickHospitalMapIcon,
    trackingClickHospitalMapList,
    trackingClickHospitalMapListReservationFilter,
    trackingClickHospitalMapListHospitalTimeFilter,
    trackingClickHospitalMapListScrap,
    trackingClickHospitalMapCurrent,
  };
};

export default useEventTrackerHospitalMap;
