import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HospitalFilterList, HospitalListParams } from '@/api/hospital';
import { DEFAULT_ADDRESS, PAGINATION_SIZE } from '@/constants/common';
import {
  DeepLinkHospitalFilter,
  Filter,
  FILTER_LIST,
  FILTER_TYPE_KEY,
  FilterHeader,
  Sort,
  SORT_FILTER,
  SORT_FILTER_NATIONWIDE,
  SORT_LIST_KEY,
  TIME_FILTER_KEY,
} from '@/constants/filter';
import { WEB_STORAGE_KEY } from '@/constants/webStorageKey';
import useEventTrackerHospitalMap from '@/hooks/useEventTrackerHospitalMap';
import useEventTrackerHospitalTab from '@/hooks/useEventTrackerHospitalTab';
import { useModal } from '@/hooks/useModal';
import useDevicePlatformStore from '@/store/useDevicePlatformStore';
import useSelectedAddressStore from '@/store/useSelectedAddressStore';
import { getSessionStorageItem } from '@/utils/webStorage';

export const FILTER_TYPE = {
  HOSPITAL_MAP: 'map',
  HOSPITAL_TAB: 'tab',
  HOSPITAL_SEARCH: 'search',
  HOSPITAL_SEARCH_NATIONWIDE: 'search_nationwide',
};

export const useHospitalFilter = (filterType: string) => {
  const { devicePlatform } = useDevicePlatformStore();
  const filterModalProps = useModal();
  const sortModalProps = useModal();
  const {
    trackingClickHospitalReservationReservationFilter,
    trackingClickHospitalReservationHospitalTimeFilter,
    trackingClickHospitalReservationListFilter,
    trackingClickHospitalReservationRecommendFilter,
  } = useEventTrackerHospitalTab();
  const { trackingClickHospitalMapRecommendFilter } = useEventTrackerHospitalMap();

  const sortType = useMemo(() => {
    if (filterType === FILTER_TYPE.HOSPITAL_TAB || filterType === FILTER_TYPE.HOSPITAL_SEARCH) {
      return SORT_FILTER[0];
    }
    return SORT_FILTER_NATIONWIDE[0];
  }, [filterType]);

  const [filterList, setFilterList] = useState<Filter[]>([]);
  const [sort, setSort] = useState(sortType);
  const [selectedFilterType, setSelectedFilterType] = useState<FilterHeader>(FILTER_LIST[0]);
  const [isReservationAvailable, setIsReservationAvailable] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const { currentLocation } = useSelectedAddressStore();

  const handleServiceFilterParams = useCallback((filterArray: Filter[]) => {
    const serviceParams = filterArray
      .filter((item: Filter) => item.filterType !== FILTER_TYPE_KEY.TIME)
      .map((item: Filter) => item.value);
    return serviceParams.length > 0 && serviceParams;
  }, []);

  const handleTimeFilterParams = useCallback((filterArray: Filter[]) => {
    const timeParams = filterArray
      .filter((item: Filter) => item.filterType === FILTER_TYPE_KEY.TIME)
      .map((item: Filter) => item.value);
    return Object.assign({}, ...timeParams.map((key) => ({ [key as string]: true })));
  }, []);

  const serviceFilterParams = useMemo(() => {
    return handleServiceFilterParams(filterList);
  }, [filterList, handleServiceFilterParams]);

  const timeFilterParams = useMemo(() => {
    return handleTimeFilterParams(filterList);
  }, [filterList, handleTimeFilterParams]);

  const filterParams: HospitalListParams = useMemo(() => {
    return {
      currentLatitude: currentLocation.lat,
      currentLongitude: currentLocation.lng,
      addressCode: DEFAULT_ADDRESS.code,
      ...timeFilterParams,
      ...(serviceFilterParams && { tagIds: serviceFilterParams }),
      ...(isReservationAvailable && { isReservationPossible: isReservationAvailable }),
      ...(isWorking && { isWorking }),
      pageable: { page: 0, size: PAGINATION_SIZE, sort: [sort.value] },
      device: { normal: true, mobile: true, tablet: true, devicePlatform },
    };
  }, [
    currentLocation.lat,
    currentLocation.lng,
    devicePlatform,
    isReservationAvailable,
    isWorking,
    serviceFilterParams,
    sort.value,
    timeFilterParams,
  ]);

  const isFilterExist = useMemo(() => {
    return filterList.length > 0;
  }, [filterList]);

  const filterEventTracker = useCallback(
    (filterArray: Filter[]) => {
      const filterMap = new Map();
      filterArray.map((item) => {
        if (filterMap.has(item.filterType)) {
          const temp = [];
          temp.push(item.label);
          filterMap.set(item.filterType, [...filterMap.get(item.filterType), ...temp]);
        } else {
          const temp = [];
          temp.push(item.label);
          filterMap.set(item.filterType, [...temp]);
        }
        return true;
      });

      filterMap.forEach((value, key) => {
        trackingClickHospitalReservationRecommendFilter({
          filterTitle: FILTER_LIST.find((item) => item.value === key)?.label || key,
          filterList: value,
        });
      });
    },
    [trackingClickHospitalReservationRecommendFilter],
  );

  const mapFilterEventTracker = useCallback(
    (filterArray: Filter[]) => {
      const eventFilterProps = {
        specialtyFilter: [] as string[],
        timeFilter: [] as string[],
        serviceFilter: [] as string[],
      };
      filterArray.forEach((item) => {
        if (item.filterType === FILTER_TYPE_KEY.EXPERTISE) {
          eventFilterProps.specialtyFilter.push(item.label);
        }
        if (item.filterType === FILTER_TYPE_KEY.TIME) {
          eventFilterProps.timeFilter.push(item.label);
        }
        if (item.filterType === FILTER_TYPE_KEY.SERVICE) {
          eventFilterProps.serviceFilter.push(item.label);
        }
      });
      trackingClickHospitalMapRecommendFilter(eventFilterProps);
    },
    [trackingClickHospitalMapRecommendFilter],
  );

  const handleFilterOpen = useCallback(
    (filter: FilterHeader) => {
      setSelectedFilterType(filter);
      filterModalProps.showModal();
    },
    [filterModalProps],
  );

  const handleIsReservationAvailableChange = useCallback(() => {
    setIsReservationAvailable(!isReservationAvailable);
    trackingClickHospitalReservationReservationFilter({ checkBoxValue: !isReservationAvailable });
  }, [isReservationAvailable, trackingClickHospitalReservationReservationFilter]);

  const handleIsWorkingChange = useCallback(() => {
    setIsWorking(!isWorking);
    trackingClickHospitalReservationHospitalTimeFilter({ checkBoxValue: !isWorking });
  }, [isWorking, trackingClickHospitalReservationHospitalTimeFilter]);

  const handleSortOpen = useCallback(() => {
    sortModalProps.showModal();
  }, [sortModalProps]);

  const handleSortSelect = useCallback(
    (selected: Sort) => {
      setSort(selected);
      trackingClickHospitalReservationListFilter({ listFilter: selected.label });
      sortModalProps.hideModal();
    },
    [sortModalProps, trackingClickHospitalReservationListFilter],
  );

  const handleFilterButtonCheck = useCallback(
    (type: string) => {
      return filterList?.some((filter: Filter) => filter.filterType === type);
    },
    [filterList],
  );

  const handleFilterTextChange = useCallback(
    (item: FilterHeader) => {
      const filteredItem = filterList?.filter((filter: Filter) => filter.filterType === item.value);
      if (filteredItem.length === 0) {
        return item.label;
      }
      if (filteredItem.length === 1) {
        return filteredItem[0].label;
      }
      return `${filteredItem[0].label} 외 ${filteredItem.length - 1}개`;
    },
    [filterList],
  );

  const handleHospitalFilterReset = useCallback(() => {
    setFilterList([]);
  }, []);

  const handleFilterResetAll = useCallback(() => {
    setFilterList([]);
    setIsWorking(false);
    setIsReservationAvailable(false);
  }, []);

  const handleFilterApply = useCallback(
    (tempArray: Filter[]) => {
      setFilterList(tempArray);
      setSelectedFilterType(FILTER_LIST[0]);
      if (filterType === FILTER_TYPE.HOSPITAL_TAB) {
        filterEventTracker(tempArray);
      }
      if (filterType === FILTER_TYPE.HOSPITAL_MAP) {
        mapFilterEventTracker(tempArray);
      }
      filterModalProps.hideModal();
    },
    [filterEventTracker, filterModalProps, filterType, mapFilterEventTracker],
  );

  const handleCloseFilter = useCallback(() => {
    setSelectedFilterType(FILTER_LIST[0]);
    filterModalProps.hideModal();
  }, [filterModalProps]);

  const handleCheckSimpleReservation = useCallback(() => {
    setIsReservationAvailable(true);
  }, []);

  const handleUncheckSimpleReservation = useCallback(() => {
    setIsReservationAvailable(false);
  }, []);

  const getFiltersFromTagIds = useCallback((tagIds: string, filterItemList?: HospitalFilterList) => {
    const tagIdArray = tagIds.toString().includes(',') ? tagIds.split(',') : [tagIds.toString()];
    const serviceFilter = (filterItemList?.serviceFilter || []).map((item) => {
      return {
        ...item,
        filterType: FILTER_TYPE_KEY.SERVICE,
      };
    });
    const expertiseFilter = (filterItemList?.expertiseFilter || []).map((item) => {
      return {
        ...item,
        filterType: FILTER_TYPE_KEY.EXPERTISE,
      };
    });
    const filterPartialArray = [...serviceFilter, ...expertiseFilter] as Filter[];
    const filters = filterPartialArray.reduce((acc, cur) => {
      if (cur.value && tagIdArray?.includes(cur.value.toString())) {
        acc.push(cur);
      }
      return acc;
    }, [] as Filter[]);
    return filters;
  }, []);

  const handleFilterParamsByQueryString = useCallback(
    (query: DeepLinkHospitalFilter, filterItemList?: HospitalFilterList) => {
      if (query.isWorking) {
        setIsWorking(query.isWorking);
      }
      if (query.isReservationPossible) {
        setIsReservationAvailable(query.isReservationPossible);
      }
      if (query.sort) {
        const querySort = query.sort.toUpperCase() as keyof typeof SORT_LIST_KEY;
        setSort(SORT_FILTER.find((item) => item.value === SORT_LIST_KEY[querySort]) || SORT_FILTER[0]);
      }
      const tempArray = [] as Filter[];
      if (query.tagIds) {
        const tagIds = query.tagIds as string;
        const filteredTagIds = getFiltersFromTagIds(tagIds, filterItemList);
        tempArray.push(...filteredTagIds);
      }
      if (query.weekEnd) {
        tempArray.push({ label: '주말진료', filterType: FILTER_TYPE_KEY.TIME, value: TIME_FILTER_KEY.WEEKEND });
      }
      if (query.allDay) {
        tempArray.push({ label: '24시간 진료', filterType: FILTER_TYPE_KEY.TIME, value: TIME_FILTER_KEY.ALLDAY });
      }
      if (query.overNight) {
        tempArray.push({ label: '야간 진료', filterType: FILTER_TYPE_KEY.TIME, value: TIME_FILTER_KEY.OVERNIGHT });
      }
      setFilterList(tempArray);
    },
    [getFiltersFromTagIds],
  );

  useEffect(() => {
    if (filterType === FILTER_TYPE.HOSPITAL_SEARCH) {
      const storageSearchData = getSessionStorageItem(WEB_STORAGE_KEY.HOSPITAL_SEARCH_DATA);
      const searchParam = storageSearchData ? JSON.parse(storageSearchData || '') : {};

      if (!isEmpty(searchParam)) {
        setSort(searchParam?.sort ?? SORT_FILTER_NATIONWIDE[0]);
        setIsReservationAvailable(searchParam?.isReservationAvailable);
        setIsWorking(searchParam?.isWorking);
        setFilterList([...searchParam.filterList]);
      }
    }
  }, [filterType]);

  return {
    filterModalProps,
    sortModalProps,
    filterList,
    filterParams,
    sort,
    selectedFilterType,
    isReservationAvailable,
    isWorking,
    isFilterExist,
    handleFilterOpen,
    handleSortOpen,
    handleSortSelect,
    handleFilterButtonCheck,
    handleFilterTextChange,
    handleHospitalFilterReset,
    handleFilterResetAll,
    handleIsReservationAvailableChange,
    handleIsWorkingChange,
    handleFilterApply,
    handleCloseFilter,
    handleServiceFilterParams,
    handleTimeFilterParams,
    handleCheckSimpleReservation,
    handleUncheckSimpleReservation,
    handleFilterParamsByQueryString,
  };
};
