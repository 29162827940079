import { useCallback } from 'react';
import useEventTrackingStore from '@/store/useEventTrackingStore';
import { getPlatform } from '@/utils/deviceUtils';
import { LOCATION_TYPE, Service } from '@/utils/tracker/constants';
import { HospitalEvent, HospitalEventProperties } from '@/utils/tracker/event/hospital';

interface TrackingClickHospitalReservationTabHospitalParams {
  hospitalId: number;
  hospitalName: string;
  isAlliance: boolean;
  isReservationAvailable: boolean;
  isOpen: boolean;
  tagNames: string[];
  distance: number;
  labelNames: string[];
  bannerText: string; // 병원 프로모션 배너 텍스트
  hospitalReviewTotalCount: number; // 누적 리뷰 개수
  hospitalReviewAvgscore: number; // 누적 리뷰 평점
}

interface TrackingSearchHospitalReservationTabKeywordParams {
  searchKeyword: string;
  searchType: string;
  searchLocationHospital: string;
}

interface TrackingClickHospitalReservationTabSearchResultHospitalParams {
  hospitalId: number;
  hospitalName: string;
  isAlliance: boolean;
  isReservationAvailable: boolean;
}

interface TrackingClickHospitalReservationRecommendFilterParams {
  filterTitle: string;
  filterList: string[];
}

interface TrackingClickHospitalReservationListFilterParams {
  listFilter: string;
}

interface TrackingClickHospitalReservationLocationFilterParams {
  locationFilter: string;
}

interface TrackingClickHospitalReservationSubFilterParams {
  checkBoxValue: boolean;
}

interface TrackingViewHospitaltabShortcutParams {
  locationId: number;
  locationName: string;
  isIntopet: boolean;
  buttonText: string;
  shortcutType: string;
}

interface TrackingClickHospitaltabShortcutParams {
  locationId: number;
  locationName: string;
  isIntopet: boolean;
  buttonText: string;
  shortcutType: string;
}

const useEventTrackerHospitalTab = () => {
  const { tracker } = useEventTrackingStore();

  const trackingViewHospitalReservationTab = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALRESERVATION_TAB, {});
  }, [tracker]);

  const trackingClickHospitalReservationTabHospital = useCallback(
    ({
      hospitalId,
      hospitalName,
      isAlliance,
      isReservationAvailable,
      isOpen,
      tagNames,
      distance,
      labelNames = [],
      bannerText = '',
      hospitalReviewTotalCount,
      hospitalReviewAvgscore,
    }: TrackingClickHospitalReservationTabHospitalParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALRESERVATION_TAB_HOSPITAL, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: hospitalId,
        [HospitalEventProperties.LOCATION_NAME]: hospitalName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.IS_RESERVATION_AVAILABLE]: isReservationAvailable,
        [HospitalEventProperties.IS_OPEN]: isOpen,
        [HospitalEventProperties.TAG_NAMES]: tagNames,
        [HospitalEventProperties.DISTANCE]: distance,
        [HospitalEventProperties.LABEL_NAMES]: labelNames,
        [HospitalEventProperties.BANNER_TEXT]: bannerText,
        [HospitalEventProperties.HOSPITAL_REVIEW_TOTAL_COUNT]: hospitalReviewTotalCount,
        [HospitalEventProperties.HOSPITAL_REVIEW_AVG_SCORE]: hospitalReviewAvgscore,
      });
    },
    [tracker],
  );

  const trackingSearchHospitalReservationTabKeyword = useCallback(
    ({ searchKeyword, searchType, searchLocationHospital }: TrackingSearchHospitalReservationTabKeywordParams) => {
      tracker.sendHospitalEvent(HospitalEvent.SEARCH_HOSPITALRESERVATION_TAB_KEYWORD, {
        [HospitalEventProperties.SEARCH_KEYWORD]: searchKeyword,
        [HospitalEventProperties.SEARCH_TYPE]: searchType,
        [HospitalEventProperties.SEARCH_LOCATION_HOSPITAL]: searchLocationHospital,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReservationTabSearchResultHospital = useCallback(
    ({
      hospitalId,
      hospitalName,
      isAlliance,
      isReservationAvailable,
    }: TrackingClickHospitalReservationTabSearchResultHospitalParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALRESERVATION_TAB_SEARCHRESULT_HOSPITAL, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: hospitalId,
        [HospitalEventProperties.LOCATION_NAME]: hospitalName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.IS_RESERVATION_AVAILABLE]: isReservationAvailable,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReservationKeywordRecentHospital = useCallback(
    ({
      hospitalId,
      hospitalName,
      isAlliance,
      isReservationAvailable,
    }: TrackingClickHospitalReservationTabSearchResultHospitalParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK__HOSPITALRESERVATION_KEYWORD_RECENTHOSPITAL, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: hospitalId,
        [HospitalEventProperties.LOCATION_NAME]: hospitalName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.IS_RESERVATION_AVAILABLE]: isReservationAvailable,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReservationRecommendFilter = useCallback(
    ({ filterTitle, filterList }: TrackingClickHospitalReservationRecommendFilterParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK__HOSPITALRESERVATION_TAB_RECOMMENDFILTER, {
        [HospitalEventProperties.RECOMMENDFILTER_NAME]: filterTitle,
        [HospitalEventProperties.RECOMMENDFILTER_LIST]: filterList,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReservationReservationFilter = useCallback(
    ({ checkBoxValue }: TrackingClickHospitalReservationSubFilterParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK__HOSPITALRESERVATION_TAB_RESERVATIONFILTER, {
        [HospitalEventProperties.CHECK_BOX]: checkBoxValue,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReservationHospitalTimeFilter = useCallback(
    ({ checkBoxValue }: TrackingClickHospitalReservationSubFilterParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK__HOSPITALRESERVATION_TAB_HOSPITALTIMEFILTER, {
        [HospitalEventProperties.CHECK_BOX]: checkBoxValue,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReservationListFilter = useCallback(
    ({ listFilter }: TrackingClickHospitalReservationListFilterParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK__HOSPITALRESERVATION_TAB_LISTFILTER, {
        [HospitalEventProperties.LISTFILTER_NAME]: listFilter,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReservationLocationFilter = useCallback(
    ({ locationFilter }: TrackingClickHospitalReservationLocationFilterParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK__HOSPITALRESERVATION_TAB_LOCATIONFILTER, {
        [HospitalEventProperties.LOCATIONFILTER_NAME]: locationFilter,
      });
    },
    [tracker],
  );

  // 병원탭에서 숏컷 배너를 조회했을 때
  const trackingViewHospitaltabShortcut = useCallback(
    ({ locationId, locationName, isIntopet, buttonText, shortcutType }: TrackingViewHospitaltabShortcutParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALTAB_SHORTCUT, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_INTOPET]: isIntopet,
        [HospitalEventProperties.BUTTON_TEXT]: buttonText,
        [HospitalEventProperties.SHORTCUT_TYPE]: shortcutType,
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
      });
    },
    [tracker],
  );

  // 병원탭에서 숏컷 배너를 클릭했을 때
  const trackingClickHospitaltabShortcut = useCallback(
    ({ locationId, locationName, isIntopet, buttonText, shortcutType }: TrackingClickHospitaltabShortcutParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALTAB_SHORTCUT, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_INTOPET]: isIntopet,
        [HospitalEventProperties.BUTTON_TEXT]: buttonText,
        [HospitalEventProperties.SHORTCUT_TYPE]: shortcutType,
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
      });
    },
    [tracker],
  );

  return {
    trackingViewHospitalReservationTab,
    trackingClickHospitalReservationTabHospital,
    trackingSearchHospitalReservationTabKeyword,
    trackingClickHospitalReservationTabSearchResultHospital,
    trackingClickHospitalReservationKeywordRecentHospital,
    trackingClickHospitalReservationRecommendFilter,
    trackingClickHospitalReservationReservationFilter,
    trackingClickHospitalReservationHospitalTimeFilter,
    trackingClickHospitalReservationListFilter,
    trackingClickHospitalReservationLocationFilter,
    trackingViewHospitaltabShortcut,
    trackingClickHospitaltabShortcut,
  };
};

export default useEventTrackerHospitalTab;
